import * as React from 'react';
import NumberFormat from "react-number-format";
import Button from "./button";

const FloatingCheckout = (props) => {
	return (
		<>
			<aside className="floating-checkout">
				{/* Should this be a h3? */}
				<h3>Checkout</h3>

				<div className="ticket-holder">

				<dl className="face-value">
					<dt>Total face value</dt>
					<dd>
						<NumberFormat value={props.basket.totalFaceValue/100} fixedDecimalScale={true} decimalScale={2} displayType={"text"} thousandSeparator={true} prefix={'£'} />
					</dd>
				</dl>

				<dl className="total">
					<dt>Total to pay<br />if successful</dt>
					<dd>
						<NumberFormat value={props.basket.total/100} fixedDecimalScale={true} decimalScale={2} displayType={"text"} thousandSeparator={true} prefix={'£'} />
					</dd>
				</dl>
				</div>

				<Button to={'/basket/checkout'}>Proceed</Button>
			</aside>
		</>
	)
}

export default FloatingCheckout